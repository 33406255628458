import React from 'react'
import DShopLeft from '../components/DShopLeft'
import DShopRight from '../components/DShopRight'

function DehlezeShopPage() {
    return (
        <>
            <div className='Ycontainer YshopPage'>
                <div className='YShopFlex YglobalFlex'>
                    <DShopLeft />
                    <DShopRight />
                </div>
            </div>
        </>
    )
}

export default DehlezeShopPage