import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../Context/Context";
import logo from "./CSS/Assets/logo.png";
import axios from "axios";
import CartDropdown from "./CartDropdown"; // Import the new CartDropdown component
import "./CSS/Header.css";
import CategoryBar from "./Category";

const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Category");
  const [categories, setCategories] = useState([]);
  const [showCartDropdown, setShowCartDropdown] = useState(false);
  const { cart, removeFromCart } = useUserContext();
  const { userData, setUserData } = useUserContext();
  const cartRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "http://39.61.51.195:8004/account/category/"
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleSearch = () => {
    navigate(`/products?search=${encodeURIComponent(searchQuery)}`);
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    setUserData(null);
    navigate("/");
  };
  const SellerAccount = () => {
    navigate("/seller-login");
  };

  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setShowCartDropdown(false);
    }
  };

  const handleScroll = () => {
    setShowCartDropdown(false);
  };

  useEffect(() => {
    if (showCartDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showCartDropdown]);

  return (
    <>
      <header className="header">
        <div className="d-flex justify-content-between align-items-center">
          {/* Logo */}
          <div className="logo">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                style={{ height: "50px", width: "auto" }}
              />
            </Link>
          </div>

          {/* Search Bar */}
          <div className="desktop-search-bar">
            <input
              type="text"
              placeholder="Search Item..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="category-select"
            >
              <option>All Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>

            <button onClick={handleSearch}>
              <i className="fa fa-search text-white"></i>
            </button>
          </div>

          {/* User Actions */}
          <div className="user-actions d-flex align-items-center justify-content-end">
            {/* Login */}
            <div className="cart position-relative">
              <div style={{marginRight:"10px"}}>
              <a
                onClick={SellerAccount}
                className="login-link"
              >
                Seller <br /> Account
              </a>
              </div>

              <Link to={"/login"}>
                <img
                  src="https://demo1.wpthemego.com/themes/sw_bosmarket/wp-content/themes/bosmarket/assets/img/icon-account2.png"
                  alt="User Icon"
                  className="me-2"
                  style={{ width: "30px", height: "30px" }}
                />
              </Link>
              {userData ? (
                <div className="login text-end me-4 d-flex flex-column">
                  <Link to="/profile" className="login-link">
                    Profile
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="btn btn-link cart-inside-title text-danger p-0"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div className="login text-end me-4 d-flex flex-column">
                  {/* <span className="hello-text">Profile!</span> */}
                  <Link to="/login" className="login-link cart-inside-title">
                    Login
                  </Link>
                </div>
              )}
            </div>

            {/* Cart */}
            <div
              className="cart position-relative"
              // onClick={() => setShowCartDropdown(!showCartDropdown)}
              onClick={handleCheckout}
              ref={cartRef}
            >
              <img
                src="https://demo1.wpthemego.com/themes/sw_bosmarket/wp-content/themes/bosmarket/assets/img/orange/cart.png"
                alt="Cart"
                className="cart-logo"
                // style={{ width: "auto", height: "auto" }}
              />

              <span className="cart-count">{cart.length}</span>
              <div className="login text-end me-4 d-flex flex-column">
                <span className="cart-total text-danger">
                  <span className="cart-inside-title">Cart</span>
                </span>
              </div>

              {/* {showCartDropdown && ( 
                <CartDropdown
                  cart={cart}
                  onRemoveItem={removeFromCart}
                  onViewCart={() => navigate("/cart")}
                  onCheckout={handleCheckout}
                />
              )} */}
            </div>
          </div>
        </div>
      </header>

      <CategoryBar />
    </>
  );
};

export default Header;
