import React from 'react'

function LoadingSpinner() {
    return (
        <div className='yLoadingSpinner'>

        </div>
    )
}

export default LoadingSpinner