import React, { useState, useEffect } from "react";
import { useUserContext } from "../../Context/Context";
import { useNavigate } from "react-router-dom"; // useNavigate instead of useHistory
import { toast } from "react-toastify"; // import toastify
import "react-toastify/dist/ReactToastify.css"; // import css
import "./CSS/Checkout.css";

const Checkout = () => {
  const { cart, clearCart } = useUserContext(); // Assuming setCart exists
  const { userData } = useUserContext();
  const navigate = useNavigate(); // useNavigate hook

  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [orderedItems, setOrderedItems] = useState(
    cart.map((item) => ({ id: item.id, quantity: 1 })) // Initialize with quantity of 1 for each item
  );

  // Function to calculate total price for all products
  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      const orderedItem = orderedItems.find((ordered) => ordered.id === item.id);
      return total + (item.price * orderedItem.quantity); // Sum price * quantity for each item
    }, 0).toFixed(2); // Return the total price for the order
  };

  // Function to handle quantity change for each product
  const handleQuantityChange = (itemId, quantity) => {
    setOrderedItems(orderedItems.map((ordered) =>
      ordered.id === itemId ? { ...ordered, quantity: Number(quantity) } : ordered
    ));
  };

  const handleOrder = async () => {
    // Check if user is logged in
    if (!userData?.id) { // Add null check here
      toast.error("You need to log in to place an order!"); // Show error toast
      navigate("/login"); // Redirect to login page using useNavigate
      return;
    }

    // Check if cart is empty
    if (cart.length === 0) {
      toast.warning("Your cart is empty."); // Show warning toast
      return;
    }

    // Create an array to hold all individual orders for each product in the cart
    const orders = cart.map((item) => {
      const orderedItem = orderedItems.find((ordered) => ordered.id === item.id);
      return {
        name: userData?.name || "You need to login", // Fallback to "Guest" if userData is null
        ordered_item: orderedItem.quantity,
        Phone_no: phoneNumber,
        address: address,
        total_price: (item.price * orderedItem.quantity).toFixed(2), // Calculate total price based on quantity
        order_status: "processing",
        refund_status: "not_requested",
        clear_status: "not_clear",
        user: userData?.id, // Fallback to null if userData is null
        product: item.id, // Product ID
        seller: item.seller_id, // Replace with dynamic seller ID if available
      };
    });

    try {
      // Loop through each order and send it via the API
      for (const order of orders) {
        const response = await fetch(
          "http://39.61.51.195:8004/account/create-order/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(order), // Use the correct order object here
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log("Order Response:", responseData);
        } else {
          const errorData = await response.json(); // Get detailed error message from the response
          console.error("Error Response Data:", errorData);
          toast.error(
            `Failed to place the order: ${
              errorData.message || response.statusText
            }`
          );
          return; // Stop further orders if one fails
        }
      }

      toast.success("All orders placed successfully!"); // Show success toast
      clearCart(); // Clear the cart after successful order
    } catch (error) {
      console.error("Error placing orders:", error);
      toast.error(
        "An error occurred while placing the order. Please try again."
      );
    }
  };

  useEffect(() => {
    // This will trigger re-calculation of total price whenever cart or orderedItems changes.
  }, [orderedItems, cart]);

  return (
    <div className="checkout container mt-5">
      <h1>{userData ? userData.name : "you need to login"}</h1> {/* Safe check for userData */}
      <ul className="list-group mb-4">
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          cart.map((item) => {
            const orderedItem = orderedItems.find((ordered) => ordered.id === item.id);
            return (
              <li
                key={item.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div>
                  <img
                    src={item.images?.[0]?.image || ""}
                    alt={item.name}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      marginRight: "10px",
                    }}
                  />
                  <span>{item.name}</span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="total-price">
                    ${(item.price * orderedItem.quantity).toFixed(2)}
                  </span>
                  <div className="form-group">
                    <label htmlFor="orderedItem">Quantity</label>
                    <input
                      type="number"
                      id="orderedItem"
                      value={orderedItem.quantity}
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                      min="1"
                    />
                  </div>
                </div>
              </li>
            );
          })
        )}
      </ul>

      <div className="form-group">
        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          className="form-control"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter your address"
        />
      </div>

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="text"
          id="phoneNumber"
          className="form-control"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Enter your phone number"
        />
      </div>

      <h4>
        Total Price: ${calculateTotalPrice()}
      </h4>

      <button className="btn btn-success" onClick={handleOrder}>
        Place Order
      </button>
    </div>
  );
};

export default Checkout;
